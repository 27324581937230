import {
  Component,
  OnInit,
  NgModule,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SIMRegistration } from '../Models/simregistration';
import { ApplicationServiceService } from '../Services/application-service.service';
import {
  FormsModule,
  FormArray,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  SIMRegistrationResponse,
  UserFiles,
} from '../Models/SIMRegistrationResponse';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TransformStringFilter } from '../Services/TransformFilter';
import { City } from '../Models/City';

import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-simregistration',
  templateUrl: './simregistration.component.html',
  styleUrls: ['./simregistration.component.scss'],
})
export class SIMRegistrationComponent implements OnInit {
  public displayCustomerDetail = true;
  public displaySIMDetail = false;
  public City: City[];
  public IsViewConsent: boolean = true;
  CityReceivedErrorMessage = false;
  @ViewChild('termandcondition') termandcondition: TemplateRef<any>;
  public VisaStatusList: any = [
    'Student Visa',
    'Work Visa',
    'Visitor Visa',
    'Any Other',
  ];
  public TravelDateStatus: string[] = ['Confirmed', 'Tentative'];
  public SIMRegistrationModel: SIMRegistration;
  public RegistrationID: string;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  public Startdate: Date;
  constructor(
    public ApplicationServiceService: ApplicationServiceService,
    public route: ActivatedRoute,
    public Router: Router,
    public TransformStringFilter: TransformStringFilter,
    private modalService: NgbModal
  ) {
    this.route.paramMap.subscribe((params) => {
      this.RegistrationID = params.get('RegistrationID');
    });
    // this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
  }

  ngOnInit(): void {
    this.Startdate = new Date();
    this.SIMRegistrationModel = new SIMRegistration();
    this.SIMRegistrationModel.CityReceived = null;
    this.SIMRegistrationModel.IsConsent = true;
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.displayCustomerDetail = true;

    this.route.paramMap.subscribe((params) => {
      this.RegistrationID = params.get('RegistrationID');
    });
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);

    this.GetCity();
  }

  OnclickFirstDIVHeader() {
    this.displayCustomerDetail = true;
    this.displaySIMDetail = false;
  }

  OnclickSecondDIVHeader() {
    this.displayCustomerDetail = false;
    this.displaySIMDetail = true;
  }

  OnClickSaveFirstDiv() {
    this.displayCustomerDetail = false;
    this.displaySIMDetail = true;
  }

  onSubmit(SIMRegistrationModel: SIMRegistration) {
    this.route.paramMap.subscribe((params) => {
      this.RegistrationID = params.get('RegistrationID');
    });

    if (this.RegistrationID != undefined || this.RegistrationID != null) {
      SIMRegistrationModel.SIMRegistrationID = this.RegistrationID;
    }

    if (
      SIMRegistrationModel.CityReceived === null ||
      SIMRegistrationModel.CityReceived === undefined ||
      SIMRegistrationModel.CityReceived === ''
    ) {
      this.CityReceivedErrorMessage = true;
      // alert('Please select the City.!');
      return;
    }

    //SIMRegistrationModel.SIMRegistrationID = this.RegistrationID;
    this.ApplicationServiceService.AddSIMRegistrationFirstPage(
      SIMRegistrationModel
    ).subscribe((result: any) => {
      this.SIMRegistrationResponseModel = JSON.parse(result);
      // this.Router.navigateByUrl('/SIMCardRegistrationCont/' + this.SIMRegistrationResponseModel.SIMRegistrationID + '/Next');
      this.Router.navigate([
        '/SIMCardRegistrationCont/' +
          this.SIMRegistrationResponseModel.SIMRegistrationID +
          '/Next',
      ]);
    });
  }
  BindDataTotheModelWhenPreviousClick(RegistrationID: string) {
    this.SIMRegistrationModel = new SIMRegistration();
    if (RegistrationID != null && RegistrationID != '') {
      this.ApplicationServiceService.GetSIMRegistrationDetail(
        RegistrationID
      ).subscribe((result: any) => {
        this.SIMRegistrationResponseModel = JSON.parse(result);
        this.SIMRegistrationModel.FirstName =
          this.SIMRegistrationResponseModel.FirstName;
        this.SIMRegistrationModel.LastName =
          this.SIMRegistrationResponseModel.LastName;
        this.SIMRegistrationModel.CityReceived =
          this.SIMRegistrationResponseModel.CityReceived;
        this.SIMRegistrationModel.Indianmobilenumber =
          this.SIMRegistrationResponseModel.Indianmobilenumber;
      });
    } else {
      // this.SIMRegistrationModel.CityReceived = 'Ahmedabad';
    }
  }

  // Get City List

  GetCity() {
    this.ApplicationServiceService.CityList().subscribe((result: City[]) => {
      this.City = result;
      let foo_object: any; // Item to remove
      foo_object = this.City.filter((obj) => obj.Name === 'All City');
      let index = this.City.findIndex((d) => d.id === foo_object.id); //find index in your array
      this.City.splice(index, 1); //remove element from array
    });
  }

  ValidateMobilenumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public onOptionsSelected(event: any) {
    const value: any = event.target.value;
    var CityName = value.substring(3, 25);
    if (CityName === 'Mumbai') {
      this.IsViewConsent = true;
      this.SIMRegistrationModel.IsConsent = true;
    } else {
      this.IsViewConsent = true;
      this.SIMRegistrationModel.IsConsent = true;
    }
    if (value != null || value != '') {
      this.CityReceivedErrorMessage = false;
    }
  }

  closeResult = '';
  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    };

    this.modalService.open(content, ngbModalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openTermCondition() {
    this.open(this.termandcondition);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
