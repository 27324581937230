import { Component, OnInit } from '@angular/core';
import { ApplicationServiceService } from '../Services/application-service.service';
import {
  FormsModule,
  FormArray,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  SIMRegistrationResponse,
  UserFiles,
} from '../Models/SIMRegistrationResponse';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
})
export class ThankyouComponent implements OnInit {
  public RegistrationID: any;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  constructor(
    public ApplicationServiceService: ApplicationServiceService,
    private route: ActivatedRoute,
    public Router: Router
  ) {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
  }

  ngOnInit(): void {
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();

    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );

    this.DisplayBindAutoFillData();
  }

  backtoHomePage() {
    this.Router.navigate(['/SIMCardRegistration']);
  }

  DisplayBindAutoFillData() {
    this.ApplicationServiceService.GetSIMRegistrationDetail(
      this.RegistrationID
    ).subscribe((Result: any) => {
      this.SIMRegistrationResponseModel = JSON.parse(Result);
      this.ApplicationServiceService.EmailNotificationOrderConfirmation(this.SIMRegistrationResponseModel.SIMRegistrationID,
        this.SIMRegistrationResponseModel.FlightNumber,
        this.SIMRegistrationResponseModel.id
        ).subscribe((response: any)=>{
        
      });
    });
  }
}
