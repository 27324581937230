export class SIMRegistration {
  id: number;
  SIMRegistrationID: string;
  FirstName: string;
  LastName: string;
  Indianmobilenumber: string;
  SourceName: string = 'CANADA';
  CityReceived: string = '';
  EmailID: string;
  IsConsent: boolean = true;
  IsTermandCondition: boolean;
}

export class UserResponse {
  userID?: number;
  appUserName: string;
  appPassword: string;
  UserRolesModel: Array<UserRolesModel>;
}

export class UserRolesModel {
  AppUserRoleName: string;
  AppUserRoleID?: number;
}

export class FileUploadModel {
  fileToUpload: File | null = null;
  id: string;
  SIMRegistrationID: string;
  uploadtype: string;
  userID: string;
}
