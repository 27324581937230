<div class="container">
    <form #sf="ngForm" id="sf" novalidate>
        <div class="row">
            <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-3 border bg-white">

                <h4 class="secondheader mb-4">Enter SIM number to get Plan detail<sup class="text-danger"> *</sup> </h4>
                <p class="secondheader2">(Starts with 89 and contains 20 digits) </p>

                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="SImNumber" id="SImNumber" placeholder="SIM No"
                                [(ngModel)]="SIMRegistrationSecModel.SImNumber" required #SImNumber="ngModel" ngModel
                                (ngModelChange)="ValidatetheSIMCard($event, SIMRegistrationSecModel.SourceName, SIMRegistrationSecModel.Indianmobilenumber)">
                            <button class="btn btn-danger" type="button" id="button-addon2"
                                (click)="ValidatetheSIMCard(SIMRegistrationSecModel.SImNumber, SIMRegistrationSecModel.SourceName, SIMRegistrationSecModel.Indianmobilenumber)">Go</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid" src="../../assets/AppImages/sim-large.png">
                    </div>
                    <div *ngIf="!SImNumber?.valid && (SImNumber?.dirty || SImNumber?.touched)">
                        SIM number required
                    </div>
                    <div class="alert alert-danger" *ngIf="SIMCardStatus">
                        <!-- We have already received following sim card {{SIMRegistrationResponseModel.SIMCardNumberICCID}}
                        registration
                        request for the against of {{SIMRegistrationResponseModel.Indianmobilenumber}}. Please try with
                        another mobile number. -->

                             <div *ngIf="SIMStatus=== 'InValid'">
                     

                              "Oops! It looks like the SIM number - {{SIMRegistrationSecModel.SImNumber}} you entered is already linked to another client account. 
                              If you need further assistance, please contact our customer support team on +91 8097735336. Otherwise, please ensure you are using the correct SIM number for registration. Thank you !!
                              

                            </div>    
                            <div *ngIf="SIMStatus !== 'InValid'">
                                Please create an new registration with another contact number. 
                            </div>     
                       
                        <br>
                        <button type="button" class="btn btn-danger" (click)="NewSaleEntry()">
                            Back to home</button>
                    </div>
                </div>
                <div id="DisplayPlanDetails" *ngIf="DisplayPlanMessage">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        Monthly Post paid plan of $35 + taxes, no payment or credit card details is required as of now.
                    </p>
                    <!-- <p>
                        This will be payable only after 20 days usage.
                    </p> -->
                    <ul>
                        <li>75GB* on 4G LTE (*Limited time promotion subject to change without notice)</li>
                        <li>Unlimited call in Canada</li>
                        <li>Unlimited local & International SMS</li>
                        <li>1000 minutes to call India</li>
                        <li>$60 setup service fee waived</li>
                    </ul>
                    <!-- <p class="sc-color fs-13">
                        This is a special price plan for new visa applicants not available on Fido Website and has huge
                        saving in cost.
                    </p> -->
                    <div>
                        <h3>
                            <b> This is a special discounted price plan provides and offers huge saving in cost as
                                compared <br>to the usual plans offered by Fido</b>
                        </h3>
                    </div>
                    <div>
                        <h3>
                            <b>First Postpaid invoice will be generated by Fido (Rogers network) and will be payable after 20
                                days</b>
                        </h3>
                    </div>

                </div>

                <div id="DisplayPlanDetailsKoodoo" *ngIf="DisplayPlanMessageKoodoo">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        This is a Prepaid plan of $45 from Koodoo (Telus). No payment is required and the plan is
                        complimentary for
                        first month. The SIM will be activated the moment you land in Canada and insert in mobile. For
                        the mobile
                        number please call another mobile and make note of it. The automated SMS takes a few hours to be
                        received. The plan includes:
                    </p>
                    <ul>
                        <li>5GB internet at 4G speed</li>
                        <li>Unlimited Canada wide calling</li>
                        <li>100 minutes to India</li>
                    </ul>
                    <!-- <p class="sc-color fs-13">
                        This is a special price plan for new visa applicants not available on Fido Website and has huge
                        saving in cost.
                    </p> -->
                    <div>
                        <h3>
                            <b> The plan is complimentary for first month and the same can be renewed on the Koodoo
                                website or through
                                any retailer</b>
                        </h3>
                    </div>
                </div>

                <div id="mandatoryfield" *ngIf="DisplayMandatoryDiv">


                    <div class="row mb-4 align-items-center">
                        
                        <div class="col-md-4">
                            <label for="FirstName" class="col-form-label textbold">First Name<sup class="text-danger">*</sup></label>
                          </div>
                          <div class="col-md-5">
                            <input type="text" class="form-control first-letter" id="FirstName" aria-label="First name" required
                              [(ngModel)]="SIMRegistrationSecModel.FirstName" #FirstName="ngModel" name="FirstName"
                     
                              >
                            <div *ngIf="!FirstName?.valid && (FirstName?.dirty || FirstName?.touched)">
                              First Name
                            </div>
                          </div>

                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="LastName" class="col-form-label textbold">Last Name<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                          <input type="text" class="form-control first-letter" id="LastName" aria-label="Last name"
                            [(ngModel)]="SIMRegistrationSecModel.LastName" required name="LastName" #LastName="ngModel"
                          
                            >
              
              
                          <div *ngIf="!LastName?.valid && (LastName?.dirty || LastName?.touched)">
                            Last Name
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="email" class="col-form-label textbold">Email ID<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                          <input type="email" class="form-control" id="EmailID" aria-label="email"
                            [(ngModel)]="SIMRegistrationSecModel.EmailID" required name="EmailID" #EmailID="ngModel"
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" [email]="true">
              
              
                          <div *ngIf="!EmailID?.valid && (EmailID?.dirty || EmailID?.touched)">
                            Email ID
                          </div>
              
                        </div>
                      </div>
                      <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="mobile" class="col-form-label textbold">Indian Mobile Number<sup
                              class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-4">
                              <input type="text" value="+91" class="form-control" disabled>
                            </div>
                            <div class="col-md-8">
                              <input type="text" class="form-control" id="Indianmobilenumber" aria-label="mobile"
                                [(ngModel)]="SIMRegistrationSecModel.Indianmobilenumber" required name="Indianmobilenumber"
                                #Indianmobilenumber="ngModel" (keypress)="ValidateMobilenumber($event)" minlength=10 maxlength=10 readonly>
                              <div *ngIf="!Indianmobilenumber?.valid && (Indianmobilenumber?.dirty || Indianmobilenumber?.touched)">
                                Mobile Number
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="date" class="col-form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div clas="col-md-5">

                                    <div class="input-group">
                                        <input placeholder="Date of Travel" matInput [matDatepicker]="pickerdot"
                                            id="DateofTravel" name="DateofTravel" class="form-control"
                                            [(ngModel)]="SIMRegistrationSecModel.DateofTravel" #Traveldate="ngModel"
                                            #DateofTravel="ngModel" required [min]="Startdate" [ngClass]="{
                                    'is-invalid': (DateofTravel?.invalid && IsSubmitted),
                                    'valid': !DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)
                               }">
                                        <mat-datepicker-toggle matSuffix [for]="pickerdot"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerdot></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="tdate" class="col-form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <select class="form-select" aria-label="Default select example"
                                [(ngModel)]="SIMRegistrationSecModel.DateofTravelTentative" name="DateofTravelTentative"
                                id="DateofTravelTentative" #DateofTravelTentative="ngModel" [ngClass]="{
                              'is-invalid': (DateofTravelTentative?.invalid && IsSubmitted),
                              'valid': !DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)
                         }">
                                <option selected>Confirmed</option>
                                <option>Tentative</option>
                            </select>
                            <div
                                *ngIf="!DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Email ID<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <input type="email" class="form-control" id="EmailID" aria-label="email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                [(ngModel)]="SIMRegistrationSecModel.EmailID" required name="EmailID" #EmailID="ngModel"
                                [ngClass]="{
                              'is-invalid': (EmailID?.invalid && IsSubmitted),
                              'valid': !EmailID?.valid && (EmailID?.dirty || EmailID?.touched)
                         }">
                            <div *ngIf="!EmailID?.valid && (EmailID?.dirty || EmailID?.touched)">
                                Email ID
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="Canadianaddress" class="col-form-label textbold">Canadian address<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <textarea  class="form-control" id="Canadianaddress" aria-label="Canadianaddress"
                              
                                [(ngModel)]="SIMRegistrationSecModel.Canadianaddress"  name="Canadianaddress" #Canadianaddress="ngModel"
                                [ngClass]="{
                              'is-invalid': (Canadianaddress?.invalid && IsSubmitted),
                              'valid': !Canadianaddress?.valid && (Canadianaddress?.dirty || Canadianaddress?.touched)
                         }" required="true"></textarea>
                             <div *ngIf="!Canadianaddress?.valid && (Canadianaddress?.dirty || Canadianaddress?.touched)">
                                Canada address
                            </div> 
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="Province" class="col-form-label textbold">Province<sup
                                    class="text-danger"></sup></label>
                        </div>
                        <div class="col-md-5">
                            <!-- <input type="text" class="form-control" id="Province" aria-label="Province"
                               
                                [(ngModel)]="SIMRegistrationSecModel.Province"  name="Province" #Province="ngModel"
                                [ngClass]="{
                              'is-invalid': (Province?.invalid && IsSubmitted),
                              'valid': !Province?.valid && (Province?.dirty || Province?.touched)
                         }"> -->
                         <select class="form-select" aria-label="Default select example" name="Province"
                                id="Province"  [(ngModel)]="SIMRegistrationSecModel.Province"
                                #Province="ngModel">
                                <option *ngFor="let Province of ProvinceList" [ngValue]="Province"
                                    [selected]="Province === Alberta">
                                    {{Province}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="PostalCode" class="col-form-label textbold">Postal Code<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <input type="text" class="form-control" id="PostalCode" aria-label="PostalCode"
                                
                                [(ngModel)]="SIMRegistrationSecModel.PostalCode"  name="PostalCode" #PostalCode="ngModel"
                                [ngClass]="{
                              'is-invalid': (PostalCode?.invalid && IsSubmitted),
                              'valid': !PostalCode?.valid && (PostalCode?.dirty || PostalCode?.touched)
                         }">
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="UniversityName" class="col-form-label textbold">UniversityName<sup
                                    class="text-danger"></sup></label>
                        </div>
                        <div class="col-md-5">
                            <input type="text" class="form-control" id="UniversityName" aria-label="UniversityName"
                               
                                [(ngModel)]="SIMRegistrationSecModel.UniversityName"  name="UniversityName" #UniversityName="ngModel"
                                [ngClass]="{
                              'is-invalid': (UniversityName?.invalid && IsSubmitted),
                              'valid': !UniversityName?.valid && (UniversityName?.dirty || UniversityName?.touched)
                         }">
                           
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center" style="display: none;">
                        <div class="col-md-4">
                            <label for="HandsetModelnumber" class="col-form-label textbold">Handset Model number<sup
                                    class="text-danger"></sup></label>
                        </div>
                        <div class="col-md-5">
                            <input type="text" class="form-control" id="HandsetModelnumber" aria-label="HandsetModelnumber"
                               
                                [(ngModel)]="SIMRegistrationSecModel.HandsetModelnumber"  name="HandsetModelnumber" #HandsetModelnumber="ngModel"
                                [ngClass]="{
                              'is-invalid': (HandsetModelnumber?.invalid && IsSubmitted),
                              'valid': !HandsetModelnumber?.valid && (HandsetModelnumber?.dirty || HandsetModelnumber?.touched)
                         }">
                           
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">

                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Passport front file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" placeholder="Front" type="file" id="passportfilename"
                            name="passportfilename"  
                            [(ngModel)]="SIMRegistrationSecModel.passportfilename"
                            #passportfilename="ngModel"  required accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportFront', SIMRegistrationSecModel, sf)">
                           
                              <div class="alert-danger" *ngIf="ActionMessageFront === 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageFront}}
                              </div>

                              <div *ngIf="ActionMessageFront !== 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageFront}}
                              </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Passport back file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" placeholder="Front" type="file" id="passportbackfilename"
                            name="passportbackfilename" 
                            [(ngModel)]="SIMRegistrationSecModel.passportbackfilename"
                            #passportbackfilename="ngModel"
                            required accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportBack', SIMRegistrationSecModel, sf)">

                            <div class="alert-danger" *ngIf="ActionMessageBack === 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageBack}}
                              </div>

                              <div *ngIf="ActionMessageBack !== 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageBack}}
                              </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">

                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Visa stamp copy file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" required placeholder="Front" type="file" id="visafilename"
                            name="visafilename" 
                            [(ngModel)]="SIMRegistrationSecModel.visafilename"
                             #visafilename="ngModel"
                            accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'Visa', SIMRegistrationSecModel, sf)" >
                            <div class="alert-danger" *ngIf="ActionMessageVisa === 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageVisa}}
                              </div>
                              <div *ngIf="ActionMessageVisa !== 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageVisa}}
                              </div>
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div>
                            <label for="IsTermandCondition" class="textboldNew">
                                           <input class="largerCheckbox" type="checkbox" id="IsFidoTermandCondition" name="IsFidoTermandCondition" value="coding"
                                             [(ngModel)]="SIMRegistrationSecModel.IsFidoTermandCondition" #IsFidoTermandCondition="ngModel"

                                             [ngClass]="{
                                 'is-invalid': IsFidoTermandCondition?.invalid,
                                 'valid': !IsFidoTermandCondition?.valid && (IsFidoTermandCondition?.dirty || IsFidoTermandCondition?.touched)
                            }" required>
                         
                            I agree to the <span class="termCondition"  (click)="openTermCondition()">Fido terms & conditions</span>.
                           </label>
                            <div *ngIf="!IsFidoTermandCondition?.valid && (IsFidoTermandCondition?.dirty || IsFidoTermandCondition?.touched)">
                             <label class="mat-error">Please read the term and conditions and accept that !
                             </label> 
                           </div> <br>
                    
                                       </div>
                                       
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-12 text-end">
                            <!-- {{sf.value | json }} -->
                            <button type="button" class="btn btn-danger" (click)="OnBackButton()"> &lt;
                                Previous</button>
                            &nbsp; &nbsp;
                            <button type="button" class="btn btn-danger" (click)="OnSubmit(SIMRegistrationSecModel)"
                                [disabled]="sf.invalid">Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="container">
        <ng-template #termandcondition let-modal data-keyboard="false" data-backdrop="static">
          <div class="modal-header" data-keyboard="false" data-backdrop="static">
            <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <app-termandconditionconsent></app-termandconditionconsent>
          </div>
        </ng-template>
      </div>

</div>