<div class="container-fluid">
<h1>Terms & conditions : -</h1>
<div>
    <p>    
    The Customer shall pay all the applicable rates, tariffs, taxes, charges and other related conditions as may be amended from time to time by UNICONNECT for the services provided and/or any other additional services, whether or not the services have been used by the Customer. 
</p>
<p>
    The Customer shall not use the mobile number for any illegal/unauthorized purpose(s). UNICONNECT shall not be liable for any loss, costs, delays, expenses, consequential loss, loss of profit, business revenue, goodwill or anticipated savings, damage whatsoever or howsoever caused due to non-functioning/lost/misplaced/misuse, etc. of the SIM card. 
</p>
<p> 
    In order to comply with the applicable law, UNICONNECT may be required to disclose any information or particulars pertaining to the Customer to any authority (viz. Statutory, Regulatory, Governmental, Semi-Governmental or Judicial) or otherwise and the Customer hereby gives his/her/its consent for such disclosure. 
</p>
<p> 
    UNICONNECT may disclose the information received from the customer to Mobile networks for conducting its services i.e activation of SIM/Plans. UNICONNECT shall be entitled to collect and use the Customer's personal data for the purposes of offering/promoting new services/additional services.  
</p>
<p> 
    This Agreement is governed by Indian law. All disputes and/or differences under this Agreement shall be referred to a sole arbitrator to be appointed by the Managing Director of UNICONNECT SIM Private Limited and the Customer hereby waives all his/her/its right to challenge the appointment of such sole arbitrator. 
</p>
<p> 
    The sole arbitrator shall follow the procedure for conducting the arbitration as prescribed in the Arbitration and Conciliation Act, 1996 and/or as amended from time to time. UNICONNECT shall have the right to initiate criminal action for any ILLEGAL USE of the SIM CARD
</p>
<p>
    &nbsp;
</p>
</div>
</div>
