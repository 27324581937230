import {
  Component,
  OnInit,
  NgModule,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FormsModule, Form, NgForm } from '@angular/forms/';
import { ReactiveFormsModule, Validators } from '@angular/forms/';
import { CommonModule } from '@angular/common';
import {
  SIMRegistration,
  UserResponse,
  UserRolesModel,
  FileUploadModel,
} from '../Models/simregistration';
import {
  SIMRegistrationSec,
  SIMRegistrationAddress,
} from '../Models/simregistration-sec';
import { ApplicationServiceService } from '../Services/application-service.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  SIMRegistrationResponse,
  UserFiles,
} from '../Models/SIMRegistrationResponse';
import { SIMValidateResponse } from '../Models/SIMValidateResponse';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sim-card-registration-cont',
  templateUrl: './sim-card-registration-cont.component.html',
  styleUrls: ['./sim-card-registration-cont.component.scss'],
})
export class SImCardRegistrationContComponent implements OnInit {
  public RegistrationID: string;
  public SIMRegistrationSecModel: SIMRegistrationAddress;
  public SIMRegistration: SIMRegistration;
  public SIMCardStatus = false;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  public SImNo: string;
  public IsValidSIMNumber = true;
  public DisplayPlanMessage = false;
  public Startdate: Date;
  public DisplayMandatoryDiv = false;
  public SIMValidateResponse: SIMValidateResponse;
  public DisplayPlanMessageKoodoo = false;
  public IsSubmitted = false;
  public NetworkName = 'Fido';
  SIMStatus: any;
  FileUploadModel: FileUploadModel;
  ActionMessageFront: string;
  ActionMessageBack: string;
  ActionMessageVisa: string;
  SuccessMessage: boolean;
  @ViewChild('termandcondition') termandcondition: TemplateRef<any>;
  // Passportback: File | null = null;
  // PassportFront: File | null = null;
  // VisaFileUpload: File | null = null;

  public ProvinceList: any = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

  constructor(
    public ApplicationServiceService: ApplicationServiceService,
    public route: ActivatedRoute,
    public Router: Router,
    private modalService: NgbModal
  ) {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
  }

  ngOnInit(): void {
    this.Startdate = new Date();
    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
  }

  ValidatetheSIMCard(SIMNumber: string, source: string, contactNumber: string) {
    this.DisplayPlanMessage = false;
    this.DisplayMandatoryDiv = false;
    this.DisplayPlanMessageKoodoo = false;
    this.DisplayMandatoryDiv = false;
    if (SIMNumber.length < 15) {
      return;
    }

    this.ApplicationServiceService.SIMCardValidationByNetwork(
      SIMNumber,
      this.NetworkName
    ).subscribe((result: any) => {
      this.SIMStatus = result.ResponseMessage;
      if (result.ResponseMessage === 'Valid') {
        this.SIMCardStatus = false;
        this.IsValidSIMNumber = false;
        if (result.Network === 'Fido') {
          this.DisplayPlanMessage = true;
          this.DisplayMandatoryDiv = true;
        } else if (result.Network === 'Koodoo') {
          this.DisplayPlanMessageKoodoo = true;
          this.DisplayMandatoryDiv = true;
        }
        this.validateIsAlreadyAssignedContact(SIMNumber, source, contactNumber);
        this.validateIsAlreadyAssignedSIMCard(SIMNumber, source, contactNumber);
      } else if (result.ResponseMessage === 'InValid') {
        if (
          this.SIMRegistrationResponseModel.SIMCardNumberICCID !== undefined ||
          this.SIMRegistrationResponseModel.SIMCardNumberICCID !== '' ||
          this.SIMRegistrationResponseModel.SIMCardNumberICCID != null
        ) {
          if (
            this.SIMRegistrationResponseModel.SIMCardNumberICCID === SIMNumber
          ) {
            this.SIMCardStatus = false;
            this.IsValidSIMNumber = false;

            this.DisplayPlanMessageKoodoo = true;
            this.DisplayMandatoryDiv = true;
          } else {
            this.SIMCardStatus = true;
            this.IsValidSIMNumber = true;
          }
        } else {
          this.SIMCardStatus = true;
          this.IsValidSIMNumber = true;
        }
      }
      // validate the
    });
  }
// validate the contact number 

validateIsAlreadyAssignedContact(
  SIMNumber: string,
  Source: string,
  ContactNumber: string
) {
  this.ApplicationServiceService.IsValidContactRegistration(
    SIMNumber,
    Source,
    ContactNumber
  ).subscribe((result: any) => {
    console.log(result);
    if (result?.name !== 'ok') {
      this.DisplayMandatoryDiv = false;
      this.SIMCardStatus = true;
      this.DisplayPlanMessage = false;
      alert(
        `Oops! It looks like the SIM number - ${result?.simNumber} you entered is already linked to another client account. If you need further assistance, please contact our customer support team on +91 8097735336. Otherwise, please ensure you are using the correct SIM number for registration. Thank you !!`
      );
      return;
    }
  });
}

// 
  validateIsAlreadyAssignedSIMCard(
    SIMNumber: string,
    Source: string,
    ContactNumber: string
  ) {
    this.ApplicationServiceService.IsSIMCardAlreadyAssign(
      SIMNumber,
      Source,
      ContactNumber
    ).subscribe((result: any) => {
      console.log(result);
      if (result?.name !== 'ok') {
        this.DisplayMandatoryDiv = false;
        this.SIMCardStatus = true;
        this.DisplayPlanMessage = false;
        alert(
          `Oops! It seems that the mobile number ${result?.registrationNumber} you entered is already associated with another client account linked to SIM number ${result?.simNumber}
          If you require further assistance, please reach out to our customer support team +91 8097735336. Otherwise, please verify that you are using the correct mobile number for registration. Thank you!`
          );
        return;
      }
    });
  }

  OnSubmit(SIMRegistrationSecModel: SIMRegistrationAddress) {
    this.IsSubmitted = true;

    // validate if SIM Number is already register with provided number..
    this.validateIsAlreadyAssignedSIMCard(
      SIMRegistrationSecModel.SImNumber,
      SIMRegistrationSecModel.SourceName,
      SIMRegistrationSecModel.Indianmobilenumber
    );

    this.validateIsAlreadyAssignedContact( SIMRegistrationSecModel.SImNumber,
      SIMRegistrationSecModel.SourceName,
      SIMRegistrationSecModel.Indianmobilenumber);

    if (
      SIMRegistrationSecModel.DateofTravel === undefined ||
      SIMRegistrationSecModel.DateofTravel == null
    ) {
      alert('Please provide the travel date.!');
      return;
    }

    if (
      SIMRegistrationSecModel.EmailID === undefined ||
      SIMRegistrationSecModel.EmailID == null ||
      SIMRegistrationSecModel.EmailID === ''
    ) {
      alert('Please provide the email id.!');
      return;
    }

    // Check the file validation

    if (
      SIMRegistrationSecModel.passportfilename === null ||
      SIMRegistrationSecModel.passportfilename === undefined ||
      SIMRegistrationSecModel.passportfilename === ''
    ) {
      alert('Please upload the passport front page.!');
      return;
    }

    if (
      SIMRegistrationSecModel.passportbackfilename === null ||
      SIMRegistrationSecModel.passportbackfilename === undefined ||
      SIMRegistrationSecModel.passportbackfilename === ''
    ) {
      alert('Please upload the passport address page.!');
      return;
    }

    if (
      SIMRegistrationSecModel.visafilename === null ||
      SIMRegistrationSecModel.visafilename === undefined ||
      SIMRegistrationSecModel.visafilename === ''
    ) {
      alert('Please upload the visa scan document.!');
      return;
    }

    if (
      SIMRegistrationSecModel.IsFidoTermandCondition === null ||
      SIMRegistrationSecModel.IsFidoTermandCondition === undefined
    ) {
      alert('Please accept the Fido term and condition');
      return;
    }

    // END

    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );

    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    this.SIMRegistrationSecModel.PlanID = 0;
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.id = 0;
    this.SIMRegistrationSecModel.SImNumber = SIMRegistrationSecModel.SImNumber;
    this.SIMRegistrationSecModel.DateofTravel =
      SIMRegistrationSecModel.DateofTravel;
    this.SIMRegistrationSecModel.DateofTravelTentative =
      SIMRegistrationSecModel.DateofTravelTentative;
    this.SIMRegistrationSecModel.EmailID = SIMRegistrationSecModel.EmailID;
    this.SIMRegistrationSecModel.Canadianaddress =
      SIMRegistrationSecModel.Canadianaddress;
    this.SIMRegistrationSecModel.HandsetModelnumber = '';
    this.SIMRegistrationSecModel.PostalCode =
      SIMRegistrationSecModel.PostalCode;
    this.SIMRegistrationSecModel.Province = SIMRegistrationSecModel.Province;
    this.SIMRegistrationSecModel.UniversityName =
      SIMRegistrationSecModel.UniversityName;
    this.SIMRegistrationSecModel.IsFidoTermandCondition =
      SIMRegistrationSecModel.IsFidoTermandCondition;
    this.SIMRegistrationSecModel.Indianmobilenumber =
      '+91' + SIMRegistrationSecModel.Indianmobilenumber;
    this.SIMRegistrationSecModel.FirstName = SIMRegistrationSecModel.FirstName;
    this.SIMRegistrationSecModel.LastName = SIMRegistrationSecModel.LastName;
    this.SIMRegistrationSecModel.EmailID = SIMRegistrationSecModel.EmailID;
    this.ApplicationServiceService.AddSIMRegistrationaddress(
      this.SIMRegistrationSecModel,
      this.RegistrationID
    ).subscribe((result: any) => {
      this.SIMRegistrationResponseModel = JSON.parse(result);

      this.Router.navigate(['/Thankyou/' + this.RegistrationID]);

      // this.Router.navigateByUrl('/SIMCardRegistratioFinal/' + this.SIMRegistrationResponseModel.SIMRegistrationID + '/Final');
      // this.Router.navigate(['/SIMCardRegistrationFinal/' + this.RegistrationID + '/Final']);
    });
  }

  OnBackButton() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration/' + this.RegistrationID]);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  NewSaleEntry() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration']);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  BindDataTotheModelWhenPreviousClick(RegistrationID: string) {
    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    if (RegistrationID != null && RegistrationID !== '') {
      this.ApplicationServiceService.GetSIMRegistrationDetail(
        RegistrationID
      ).subscribe((result: any) => {
        this.SIMRegistrationResponseModel = JSON.parse(result);
        this.SIMRegistrationSecModel.SIMRegistrationID =
          this.SIMRegistrationResponseModel.SIMRegistrationID;
        this.SIMRegistrationSecModel.SImNumber =
          this.SIMRegistrationResponseModel.SIMCardNumberICCID;
        this.SIMRegistrationSecModel.PlanID =
          this.SIMRegistrationResponseModel.PlanID;
        this.SIMRegistrationSecModel.id = this.SIMRegistrationResponseModel.id;
        this.SIMRegistrationSecModel.FirstName =
          this.SIMRegistrationResponseModel.FirstName;
        this.SIMRegistrationSecModel.LastName =
          this.SIMRegistrationResponseModel.LastName;
        this.SIMRegistrationSecModel.Indianmobilenumber =
          this.SIMRegistrationResponseModel.Indianmobilenumber.replace(
            '+91',
            ''
          );
        this.SIMRegistrationSecModel.EmailID =
          this.SIMRegistrationResponseModel.EmailID;
        this.SIMRegistrationSecModel.Province =
          this.SIMRegistrationResponseModel.Province;
        this.SIMRegistrationSecModel.PostalCode =
          this.SIMRegistrationResponseModel.Zipcode;
        this.SIMRegistrationSecModel.UniversityName =
          this.SIMRegistrationResponseModel.StudyingUniversityName;
        this.SIMRegistrationSecModel.Canadianaddress =
          this.SIMRegistrationResponseModel.Line1;
        this.SIMRegistrationSecModel.DateofTravel =
          this.SIMRegistrationResponseModel.DateofTravel;
        this.SIMRegistrationSecModel.SourceName =
          this.SIMRegistrationResponseModel.FlightNumber;

        if (
          this.SIMRegistrationSecModel.SImNumber !== undefined &&
          this.SIMRegistrationSecModel.SImNumber !== null &&
          this.SIMRegistrationSecModel.SImNumber !== ''
        ) {
          this.ValidatetheSIMCard(
            this.SIMRegistrationSecModel.SImNumber,
            this.SIMRegistrationResponseModel.FlightNumber,
            this.SIMRegistrationResponseModel.Indianmobilenumber
          );
        }
        if (
          this.SIMRegistrationSecModel.DateofTravelTentative === undefined ||
          this.SIMRegistrationSecModel.DateofTravelTentative === null ||
          this.SIMRegistrationSecModel.SImNumber === ''
        ) {
          this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
        }
      });
    } else {
      this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    }

    // validate If SIM Card is already assign

    this.ApplicationServiceService.IsSIMCardAlreadyAssign(
      this.SIMRegistrationSecModel.SImNumber,
      this.SIMRegistrationSecModel.SourceName,
      this.SIMRegistrationSecModel.Indianmobilenumber
    ).subscribe((result: any) => {});
  }
  handleFileInputPassportFront(
    files: FileList,
    Type: any,
    SIMRegistrationSecModel: SIMRegistrationAddress,
    f: NgForm
  ) {
    this.FileUploadModel = new FileUploadModel();
    this.FileUploadModel.fileToUpload = files.item(0);
    const fileSizeinMB = this.FileUploadModel.fileToUpload.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100;
    if (Type === 'Visa') {
      if (size >= 1.5) {
        this.ActionMessageVisa =
          'Maximum file size is allowed to upload only 1.5 MB';
        f.controls['visafilename'].setValidators(Validators.required);
        SIMRegistrationSecModel.visafilename = null;
        return;
      }
    } else if (Type === 'PassportFront') {
      if (size >= 1.5) {
        f.controls['passportfilename'].setValidators(Validators.required);
        this.ActionMessageFront =
          'Maximum file size is allowed to upload only 1.5 MB';
        SIMRegistrationSecModel.passportfilename = null;
        return;
      }
    } else if (Type === 'PassportBack') {
      if (size >= 1.5) {
        f.controls['passportbackfilename'].setValidators(Validators.required);
        this.ActionMessageBack =
          'Maximum file size is allowed to upload only 1.5 MB';
        SIMRegistrationSecModel.passportbackfilename = null;
        return;
      }
    }

    this.FileUploadModel.SIMRegistrationID = this.RegistrationID;
    this.FileUploadModel.id = '0';
    this.FileUploadModel.uploadtype = Type;
    this.FileUploadModel.userID = '3';
    this.ApplicationServiceService.PostFile(this.FileUploadModel).subscribe(
      (result: any) => {
        this.SuccessMessage = true;
        if (Type === 'Visa') {
          this.ActionMessageVisa = 'File has been uploaded.!';
        } else if (Type === 'PassportFront') {
          this.ActionMessageFront = 'File has been uploaded.!';
        } else if (Type === 'PassportBack') {
          this.ActionMessageBack = 'File has been uploaded.!';
        }
        setTimeout(
          function () {
            this.SuccessMessage = false;
          }.bind(this),
          5000
        );
      }
    );
  }

  closeResult = '';
  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    };

    this.modalService.open(content, ngbModalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openTermCondition() {
    this.open(this.termandcondition);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
