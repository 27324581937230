import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { PlanLineItem, SIMPlanDetail } from '../Models/simplan-detail';
import { SIMRegistration, FileUploadModel } from '../Models/simregistration';
import { SIMRegistrationFinal } from '../Models/simregistration-final';
import {
  SIMRegistrationSec,
  SIMRegistrationAddress,
} from '../Models/simregistration-sec';
import {
  SIMRegistrationResponse,
  UserFiles,
} from '../Models/SIMRegistrationResponse';
import { City } from '../Models/City';
import { Expectedperiod } from '../Models/Expectedperiod';

import { SIMValidateResponse } from '../Models/SIMValidateResponse';

@Injectable({
  providedIn: 'root',
})
export class ApplicationServiceService {
  public BaseUrl: string = 'https://www.canadasimregister.com/APIWEBSERVICE/';
 //  public BaseUrl = 'http://localhost:61908/';
  EmailServiceURL: string = 'https://www.koodosimindia.com/KoodoEmailService/';

  constructor(private http: HttpClient) {}

  GetBaseAPIURL() {
    return 'https://www.canadasimregister.com/APIWEBSERVICE/';
  }

  //
  // Add SIM Registration First Page
  AddSIMRegistrationFirstPage(
    SIMRegistration: SIMRegistration
  ): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    //let URL = this.BaseUrl + 'api/Registration/Add/Canada';
    const URL = this.BaseUrl + 'api/Registration/Add/CanadaConsent';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistration, {
      headers: headers,
    });
  }

  // Add SIM Registration First Page
  AddSIMRegistrationSec(
    SIMRegistrationSec: SIMRegistrationSec,
    RegistrationID: string
  ): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    //let URL = this.BaseUrl + 'api/Registration/Add/Next';
    const URL = this.BaseUrl + 'api/Registration/Add/Next';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationSec, {
      headers: headers,
    });
  }

  // Add SIM Registration First Page
  AddSIMRegistrationaddress(
    SIMRegistrationSec: SIMRegistrationAddress,
    RegistrationID: string
  ): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    //let URL = this.BaseUrl + 'api/Registration/Add/Next';
    const URL = this.BaseUrl + 'api/Registration/UpdateCanadaFidoRequest';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationSec, {
      headers: headers,
    });
  }

  // Add SIM Registration First Page
  AddSIMRegistrationFinal(
    SIMRegistrationFinal: SIMRegistrationFinal,
    RegistrationID: string
  ): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    const URL = this.BaseUrl + 'api/Registration/Add/Final';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationFinal, {
      headers: headers,
    });
  }

  //   Get SIM Plan
  GetSIMPlan(SIMNumber: string): Observable<SIMPlanDetail> {
    const headers = { 'content-type': 'application/json' };
    const URL = this.BaseUrl + 'api/SIMPlan/SIMPlan?SIMPlan=' + SIMNumber;
    return this.http.get<SIMPlanDetail>(URL);
  }

  // Get SIM Registration Details from SIM Registration ID

  GetSIMRegistrationDetail(
    RegistrationID: string
  ): Observable<SIMRegistrationResponse> {
    const headers = { 'content-type': 'application/json' };
    const URL = this.BaseUrl + 'api/Registration/Get/' + RegistrationID;
    return this.http.get<SIMRegistrationResponse>(URL);
  }

  //   Get SIM Plan
  ValidateSIMCard(SIMNumber: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const URL =
      this.BaseUrl + 'api/SIMPlan/SIMPlan/Validate?SINICCD=' + SIMNumber;
    return this.http.get<any>(URL);
  }

  //   Get City List
  CityList(): Observable<City[]> {
    const headers = { 'content-type': 'application/json' };
    const URL = this.BaseUrl + 'api/SIMPlan/City';
    return this.http.get<City[]>(URL);
  }
  //
  //   Get GetExpectedperiods List
  GetExpectedperiods(): Observable<Expectedperiod[]> {
    const headers = { 'content-type': 'application/json' };
    const URL = this.BaseUrl + 'api/UserService/Expectedperiods';
    return this.http.get<Expectedperiod[]>(URL);
  }

  //  Get NEW SIM Validation Response
  SIMCardValidationNew(SIMNumber: string): Observable<SIMValidateResponse> {
    const headers = { 'content-type': 'application/json' };
    const URL =
      this.BaseUrl + 'api/SIMPlan/SIMPlan/ValidateNEW?SINICCD=' + SIMNumber;
    return this.http.get<SIMValidateResponse>(URL);
  }

  //  Get NEW SIM Validation Response
  SIMCardValidationByNetwork(
    SIMNumber: string,
    SIMNetwork: string
  ): Observable<SIMValidateResponse> {
    const headers = { 'content-type': 'application/json' };
    let URL =
      this.BaseUrl +
      `api/SIMPlan/SIMPlan/ValidatebySource?SINICCD=${SIMNumber}&NetworkName=${SIMNetwork}`;
    return this.http.get<SIMValidateResponse>(URL);
  }

  PostFile(FileUploadModel: FileUploadModel): Observable<any> {
    const endpoint = `${this.BaseUrl}api/upload/file`;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    const formData: FormData = new FormData();
    formData.append(
      'File',
      FileUploadModel.fileToUpload,
      FileUploadModel.fileToUpload.name
    );
    formData.append('SIMRegistrationID', FileUploadModel.id);
    formData.append('RegistrationGUID', FileUploadModel.SIMRegistrationID);
    formData.append('uploadtype', FileUploadModel.uploadtype);
    formData.append('UserID', FileUploadModel.userID);
    return this.http.post<any>(endpoint, formData);
  }

  //  Get NEW SIM Validation Response
  IsSIMCardAlreadyAssign(
    SIMNumber: string,
    Source: string,
    ContactNumber: string
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const URL =
      this.BaseUrl +
      `api/Registration/IsSIMCardAlreadyAssign/${SIMNumber}/${Source}/${ContactNumber}`;
    return this.http.get<any>(URL);
  }

  IsValidContactRegistration(
    SIMNumber: string,
    Source: string,
    ContactNumber: string
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const URL =
      this.BaseUrl +
      `api/Registration/IsNumberAlreadyExists/${SIMNumber}/${Source}/${ContactNumber}`;
    return this.http.get<any>(URL);
  }



  EmailNotificationOrderConfirmation(
    Registrationid: any,
    Source: string,
    SaleID: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const URL =
      this.EmailServiceURL +
      `api/EmailTemplate/SendEmailNotificationOrderConfirmed/${SaleID}/${Source}?RegisterID=${Registrationid}`;
    return this.http.post<any>(URL, { headers });
  }
}
